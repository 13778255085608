<template>
  <div class="dashboard-pop-edit-container">
    <a-modal
      title="用户登录记录"
      :visible="visible"
      :confirm-loading="confirmLoading"
      :footer="null"
      @ok="handleOk"
      @cancel="handleCancel"
      width="800px"
    >
      <a-table
        :columns="columns"
        :data-source="dataSource"
        :loading="loading"
        :pagination="pageOptions"
        size="middle"
      >
      </a-table>
    </a-modal>
  </div>
</template>

<script>
const columns = [
  {
    title: "UID",
    dataIndex: "uid",
    key: "uid",
  },
  {
    title: "登录时间",
    dataIndex: "created_at",
    key: "created_at",
  },
  {
    title: "登录IP",
    dataIndex: "op_ip",
    key: "op_ip",
  },
  {
    title: "登录地址",
    dataIndex: "city",
    key: "city",
  },
];

export default {
  data() {
    return {
      confirmLoading: false,
      loading: false,
      columns,
      dataSource:[],
      pageOptions:{
        defaultCurrent: 1,
        pageSize: 10,
        pageSizeOptions:['10', '30', '50'],
        showSizeChanger:true,
        showQuickJumper:true,
        showTotal: total => `共${total}条`
      }
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    uid: {
      type: [String, Number],
    },
  },
  computed: {},
  mounted() {
    this.login_history();
  },
  methods: {
    login_history() {
      this.$api.get(`v1/user/login_history?uid=${this.uid}`).then((res) => {
        console.log(res);
        if(res.code==200){
          this.dataSource=res.data.list.map((d) => {
            d.key = d.created_at
            return d
          })
        }else{
          this.$message.error(res.message)
        }
      });
    },
    handleOk(e) {
      this.$emit("handleOk");
    },
    handleCancel(e) {
      this.$emit("handleCancel");
    },
    onChange(date, dateString) {
      console.log(date, dateString);
    },
  },
};
</script>

<style lang="less" scoped>
.dashboard-pop-edit-container {
}
</style>
