<template>
    <div class="other-container">
      <search-card />
      <table-card style="margin-top:30px" />
    </div>
</template>

<script>

import { 
searchCard,
tableCard
} from '@/components/other'

export default {
  name: "other",
  components: {
    searchCard,
    tableCard
  },
  data() {
    return {

    };
  },
  computed: {
  },
  mounted(){

  },
  methods: {

  }
};
</script>

<style lang="less" scoped>
.other-container {
  width: 100%;

}
</style>
